import React from "react";
import { Link } from "react-router-dom";





const PhishSchool = () => {
    return (
      <div className="bg-white text-white w-full h-screen">
        <header className="flex justify-between w-full p-5">
          <div className="flex items-center ">
          
            <img src='./logo.png' alt="logo" className='h-20'/>
          </div>
          <nav className="flex space-x-4">
            <Link to="/" className="text-lg font-medium text-blue-700">Home</Link>
          </nav>
        </header>
        
        <div className="mt-20 flex flex-col items-center justify-center flex-grow">
          <p className="text-lg mt-20 text-blue-700">We Are</p>
          <h1 className="text-6xl md:text-8xl font-bold text-blue-900">LAUNCHING SOON</h1>
          <p className="text-4xl font-extrabold text-black opacity-60 mt-2">STAY TUNED</p>
        </div>
      </div>
    );
  };
  
  export default PhishSchool;