import React from 'react';
import Navbar from '../components/HomeNav';
import Footer from '../components/Footer';
// import TestimonialList from './TestimonialList';

const About = () => {
  return (
    <>
      <Navbar />
      <div className="max-w-screen-xl mx-auto px-5 py-10">
        {/* Heading */}
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-gray-800 sm:text-5xl mt-10">
            About Us
          </h1>
        </div>

        {/* First Section */}
        <div className="sm:flex sm:space-x-8 items-center mb-10">
          <div className="sm:w-1/2 p-5">
            <div className="text">
              <h2 className="text-blue-700 my-4 font-bold text-3xl sm:text-4xl">
                About <span className="text-blue-700">Phishinstinct</span>
              </h2>
              <p className="text-gray-700 text-justify">
                Phishinstinct is a state-of-the-art phishing simulation tool designed to bolster organizations' cybersecurity defenses. Our platform combines cutting-edge technology with integrated employee training and awareness program support capabilities, providing a comprehensive solution for combating phishing attacks. With a user-friendly dashboard and advanced analytics, Phishinstinct empowers organizations to simulate realistic phishing scenarios, track employee responses, and identify areas for improvement. With Phishinstinct, organizations can strengthen their security posture, foster a culture of security awareness, and effectively mitigate the risks associated with phishing attacks.
              </p>
            </div>
          </div>
          <div className="sm:w-1/2 p-5 text-center">
            <img src="https://i.imgur.com/WbQnbas.png" alt="Company Logo" className="mx-auto max-w-full h-auto" />
          </div>
        </div>

        {/* Second Section */}
        <div className="sm:flex sm:space-x-8 items-center">
          <div className="sm:w-1/2 p-5 text-center order-2 sm:order-1">
            <img src="/phishschool.png" alt="Company Logo" className="mx-auto max-w-full h-auto" />
          </div>
          <div className="sm:w-1/2 p-5 order-1 sm:order-2">
            <div className="text">
              <h2 className="text-blue-700 my-4 font-bold text-3xl sm:text-4xl">
                About <span className="text-blue-700">PhishSchool</span>
              </h2>
              <p className="text-gray-700 text-justify">

              Phishinstinct's integrated Learning Management System (LMS) is designed to enhance your organization's cybersecurity awareness by delivering targeted, interactive training programs. Our LMS offers tailored courses and real-time assessments that align with your organization’s specific needs, ensuring that employees are not just aware but actively engaged in learning how to identify and counter phishing threats. The system tracks progress, provides detailed analytics, and adapts content based on user performance, creating a personalized learning experience. By using Phishinstinct's LMS, organizations can cultivate a proactive security culture, reduce the likelihood of successful phishing attacks, and ensure that their workforce is continuously updated on the latest cybersecurity best practices.              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <TestimonialList /> */}
      <Footer />
    </>
  );
}

export default About;
