import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
 

<footer class="bg-white rounded-lg dark:bg-white m-4">
<hr class="mt-4 border-gray-200 sm:mx-auto dark:border-gray-400 " />
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
            <Link to="/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                <img src="./PhishInstinct_black.png" class="h-20" alt="Flowbite Logo" />
               
            </Link>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-700 sm:mb-0 dark:text-gray-700">
                <li>
                    <Link to="/about" class="hover:underline me-4 md:me-6">About</Link>
                </li>
                <li>
                    <Link to="/plans" class="hover:underline me-4 md:me-6">Pricing</Link>
                </li>
                <li>
                    <Link to="https://school.phishinstinct.com" class="hover:underline me-4 md:me-6">LMS</Link>
                </li>
                <li>
                    <Link to="/contact" class="hover:underline">Contact</Link>
                </li>
            </ul>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-400 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-700">© 2024 <Link to="/" class="hover:underline">Newtons Apple Security Solutions LLP</Link>. All Rights Reserved.</span>
    </div>
</footer>


  )
}

export default Footer