import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./utils/auth";
import Home from "./pages/Home";
import HomeNav from './components/HomeNav';
import Footer from "./components/Footer";
import Pricing from "./components/Pricing";
import ContactPage from "./pages/ContactPage";
import About from "./pages/About";
import ErrorPage from "./pages/ErrorPage";
import Bookdemo from "./components/Bookdemo";
// import RequireAuth from './utils/RequireAuth';
// import DashBoard from './pages/DashBoard';
// import Charts from './pages/Charts';
// import Campaign from './pages/Campaign'
// import Users from "./pages/Users"
// import CampaignDetails from './pages/CampaignDetails'
// import CampaignRecords from './pages/CampaignRecords'
// import Phished_TTL from './pages/Phished_TTL'
// import Phished from './pages/Phished'
// import Login from './pages/Login'
// import Register from './components/Register'
import PhishSchool from "./pages/PhishSchool";
import { useState } from "react";
import Loader from "./components/Loader";


function App() {
  const [isVideoEnded,SetIsVideoEnded]= useState(false);

  const handleVideoEnded = ()=>{
    SetIsVideoEnded(true);
  }


  return (
    <AuthProvider>
     {!isVideoEnded && <Loader onVideoEnd={handleVideoEnded}/>}
     {isVideoEnded &&(
      <Router>
        <Routes>
          {/* <Route
            exact
            path="/dashboard"
            element={
              <RequireAuth>
                <DashBoard />
              </RequireAuth>
            }
          />   */}
          {/* <Route
            exact
            path="/charts"
            element={
              <RequireAuth>
                <Charts />
              </RequireAuth>
            }
          />  */}
          {/* <Route
            exact
            path="/campaign"
            element={
              <RequireAuth>
                <Campaign />
              </RequireAuth>
            }
          />  */}
          {/* <Route
            exact
            path="/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />  */}

          {/* <Route
            path="/campaignrecords/:id"
            element={
              <RequireAuth>
                <CampaignRecords />
              </RequireAuth>
            }
          />  */}
          {/* <Route
            path="/campaigndetails/:uuid/:id"
            element={
              <RequireAuth>
                <CampaignDetails />
              </RequireAuth>
            }
          />  */}

          {/* <Route exact path="/phished_ttl" element={<Phished_TTL />} /> 
           <Route exact path="/phished" element={<Phished />} /> 
           <Route exact path="/login" element={<Login />} /> 
           <Route path="/register" element={<Register/>} /> */}


          <Route
            path="/"
            element={
              <div className="font-sans bg-custom-blue">
                <HomeNav />
                <Home />
                <Footer />
              </div>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/plans" element={<Pricing />} />
          <Route path="/bookdemo" element={<Bookdemo />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/phishschool" element={<PhishSchool />} />
        </Routes>
      </Router>
     )}
    </AuthProvider>
  );
}

export default App;