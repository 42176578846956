import React, { useState } from 'react';
import Navbar from './HomeNav';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bookdemo = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const googleFormURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe77_FHw3ZGTCsIb6KHpOEjUNfyKXjRPvFdK2fdQob8kQEnVg/formResponse?pli=1';
  
    const formDataToSend = new FormData();
    formDataToSend.append('entry.1741336708', formData.name);
    formDataToSend.append('entry.1670878758', formData.email);
    formDataToSend.append('entry.11263744', formData.message);
  
    try {
      await fetch(googleFormURL, {
        method: 'POST',
        mode: 'no-cors',
        body: formDataToSend
      });
      toast.success("Thank you! Your demo request has been successfully submitted. We will get back to you on your submitted email.");
    } catch (error) {
      toast.error("There was an error submitting your request. Please try again later.");
    }
  };

  return (
    <>
      <Navbar />
      <section className="body-font relative bg-white text-gray-600">
        <div className="container mx-auto px-5 py-24">
          <div className="mb-12 text-center">
            <h1 className="title-font mb-4 text-3xl font-extrabold sm:text-4xl text-gray-900">Request a Demo</h1>
            <p className="mx-auto text-lg leading-relaxed lg:w-2/3 text-gray-700">
              Discover how our cutting-edge phishing prevention solutions can secure your business. Fill out the form below, and one of our experts will reach out to schedule your personalized demo.
            </p>
          </div>

          <div className="mx-auto md:w-2/3 lg:w-1/2">
            <form onSubmit={handleSubmit} className="-m-2 flex flex-wrap">
              <div className="w-1/2 p-2">
                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="peer w-full rounded border border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                    placeholder="Name"
                    required
                  />
                  <label
                    htmlFor="name"
                    className="absolute left-4 -top-6 bg-white text-sm text-blue-500 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-white peer-placeholder-shown:text-gray-500 peer-focus:left-4 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Full Name
                  </label>
                </div>
              </div>

              <div className="w-1/2 p-2">
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="peer w-full rounded border border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                    placeholder="Email"
                    required
                  />
                  <label
                    htmlFor="email"
                    className="absolute left-4 -top-6 bg-white text-sm text-blue-500 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-white peer-placeholder-shown:text-gray-500 peer-focus:left-4 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Email Address
                  </label>
                </div>
              </div>

              <div className="mt-4 w-full p-2">
                <div className="relative">
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="peer h-32 w-full resize-none rounded border border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-transparent outline-none transition-colors duration-200 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                    placeholder="Message"
                    required
                  ></textarea>
                  <label
                    htmlFor="message"
                    className="absolute left-4 -top-6 bg-white text-sm text-blue-500 transition-all peer-placeholder-shown:left-4 peer-placeholder-shown:top-2 peer-placeholder-shown:bg-white peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:left-4 peer-focus:-top-6 peer-focus:text-sm peer-focus:text-blue-500"
                  >
                    Message (Optional)
                  </label>
                </div>
              </div>

              <div className="w-full p-2">
                <button
                  type="submit"
                  className="mx-auto flex rounded bg-blue-600 py-2 px-8 text-lg text-white hover:bg-blue-700 focus:outline-none"
                >
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Bookdemo;
