import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import Navbar from "./HomeNav";
import { Fade, Slide } from "react-awesome-reveal";
import Footer from "./Footer";
import { FaToggleOn, FaToggleOff, FaRupeeSign } from "react-icons/fa";
import { ToastContainer,toast } from "react-toastify";


const Plans = () => {
    const [togglecards, settoggleCards] = useState(false);

    const showExtracard = () => {
        settoggleCards(!togglecards);
    };

    const showalert = () => {
        toast.success(
            "Plans will be activated soon. Till then, register for a free trial."
        );
    };

    return (
        <div className="min-h-screen">
            <Navbar />
            <section className="bg-white dark:bg-white">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-gray-900">
                            Designed for business teams like yours
                        </h2>
                        <p className=" text-justify mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-700">
                        Select the plan that aligns perfectly with your requirements, or contact us for a personalized Enterprise solution tailored to your organization's unique needs.<span className="text-center w-full"> Unlock the flexibility, power, and security of Phishinstinct today!</span>
                        </p>
                    </div>
                    <div className="flex items-center mt-8 mb-10 text-xl justify-center">
                        <label
                            className={`mr-2 text-gray-900 ${
                                togglecards ? "line-through" : ""
                            }`}
                        >
                            One Time
                        </label>
                        <div className="cursor-pointer" onClick={showExtracard}>
                            {togglecards ? (
                                <FaToggleOn size={50} color="#388E3C" />
                            ) : (
                                <FaToggleOff size={50} color="#1976D2" />
                            )}
                        </div>
                        <label
                            className={`ml-2 text-gray-900 ${
                                !togglecards ? "line-through" : ""
                            }`}
                        >
                            Prepetual
                        </label>
                    </div>

                    <div className="">
                        {!togglecards && (
                            <div className=" flex flex-col items-center justify-center lg:grid-cols-3 sm:gap-6 xl:gap-10 bg-white">
                                <Fade triggerOnce={true}>
                                    <div className=" flex flex-col  text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-white dark:text-gray-900">
                                        <h3 className="mb-4 text-2xl font-semibold">
                                            Monthly
                                        </h3>
                                        <p className="font-light text-gray-500 sm:text-lg dark:text-gray-700">
                                        Affordable, flexible plan with basics.
                                        </p>
                                        <div className="flex justify-center items-center my-8">
                                            <span className="flex items-baseline mr-2 text-5xl font-extrabold">
                                                <FaRupeeSign />
                                                99
                                            </span>
                                            <span className="text-gray-500 dark:text-gray-700">
                                                /user/month
                                            </span>
                                        </div>

                                        <ul
                                            role="list"
                                            className="justify-center mb-8 space-y-4 text-center items-center"
                                        >
                                            <li className="flex items-center space-x-3">
                                                <FaCheck
                                                    size={20}
                                                    color="green"
                                                />
                                                <span>Unlimited Campaigns</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaCheck
                                                    size={20}
                                                    color="green"
                                                />
                                                <span>
                                                    User Behavioral Analytics
                                                </span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaCheck
                                                    size={20}
                                                    color="green"
                                                />
                                                <span>Standard Report</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaCheck
                                                    size={20}
                                                    color="green"
                                                />
                                                <span>Awareness Content</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaTimes
                                                    size={20}
                                                    color="red"
                                                />
                                                <span>
                                                    Free On-Call Support:Mon-Fri{" "}
                                                </span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaTimes
                                                    size={20}
                                                    color="red"
                                                />
                                                <span>
                                                    Schedular Randomization
                                                </span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <FaTimes
                                                    size={20}
                                                    color="red"
                                                />
                                                <span>LMS Integration</span>
                                            </li>
                                        </ul>
                                        <button
                                            onClick={showalert}
                                            className="text-white bg-[#2351E5] hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-byan-700"
                                        >
                                            Buy
                                        </button>
                                    </div>
                                </Fade>
                            </div>
                        )}
                        {togglecards && (
                            <div className=" flex flex-col items-center justify-center lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 bg-white">
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-white dark:text-gray-900">
                                    <h3 className="mb-4 text-2xl font-semibold">
                                        Phished
                                    </h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-700">
                                    Essential defense with important core features.
                                    </p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="flex items-baseline mr-2 text-5xl font-extrabold">
                                            <FaRupeeSign />
                                            99
                                        </span>
                                        <span className="text-gray-500 dark:text-gray-700">
                                            /user/month
                                        </span>
                                    </div>

                                    <ul
                                        role="list"
                                        className="mb-8 space-y-4 text-center items-center"
                                    >
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Unlimited Campaigns</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>
                                                User Behavioral Analytics
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Standard Report</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Awareness Content</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaTimes size={20} color="red" />
                                            <span>
                                                Free On-Call Support{" "}
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaTimes size={20} color="red" />
                                            <span>Schedular Randomization</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaTimes size={20} color="red" />
                                            <span>LMS Integration</span>
                                        </li>
                                    </ul>
                                    <button
                                        onClick={showalert}
                                        className="text-white bg-[#2351E5] hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-byan-700"
                                    >
                                        Buy
                                    </button>
                                </div>
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-white dark:text-gray-900">
                                    <h3 className="mb-4 text-2xl font-semibold">
                                        Sharked
                                    </h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-700">
                                    Comprehensive security with advanced options.
                                    </p>
                                    <div className="flex justify-center items-baseline my-4">
                                        <div className="flex justify-center items-baseline my-4">
                                            <span className="flex items-baseline mr-2 text-5xl font-extrabold">
                                                <FaRupeeSign />
                                                149
                                            </span>
                                            <span className="text-gray-500 dark:text-gray-700">
                                                /user/month
                                            </span>
                                        </div>
                                    </div>

                                    <ul
                                        role="list"
                                        className="mb-8 space-y-4 text-center items-center"
                                    >
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Unlimited Campaigns</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>
                                                User Behavioral Analytics
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Standard Report</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Awareness Content</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>
                                                Free On-Call Support:Mon-Fri{" "}
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Schedular Randomization</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>LMS Integration</span>
                                        </li>
                                    </ul>
                                    <button
                                        onClick={showalert}
                                        className="text-white bg-[#2351E5] hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-byan-700"
                                    >
                                        Buy
                                    </button>
                                </div>
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-white dark:text-gray-700">
                                    <h3 className="mb-4 text-2xl font-semibold">
                                        Whaled
                                    </h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-700">
                                    Enterprise-level protection and full support.
                                    </p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-4xl font-extrabold text-gray-900">
                                            Contact Sales
                                        </span>
                                        <span className="text-gray-900 dark:text-gray-700"></span>
                                    </div>

                                    <ul
                                        role="list"
                                        className="mb-8 space-y-4 text-center items-center"
                                    >
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Unlimited Campaigns</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>
                                                User Behavioral Analytics
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Standard Report</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Awareness Content</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>
                                                Free On-Call Support:24X7{" "}
                                            </span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>Schedular Randomization</span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            <FaCheck size={20} color="green" />
                                            <span>LMS Integration</span>
                                        </li>
                                    </ul>
                                    <button
                                        onClick={showalert}
                                        className="text-white bg-[#2351E5] hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-700"
                                    >
                                        Buy
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <ToastContainer/>
            <Footer />
        </div>
    );
};

export default Plans;
