import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqsData = [
    {
      question: "How can phishing simulation benefit my organization?",
      answer: "Phishing simulation helps raise employee awareness about phishing threats, educates them identifying suspicious emails, links, and attachments, and enhances their ability to respond effectively. By regularly conducting phish simulations, organizations can proactively identify vulnerabilities, train employees, and strengthen their overall cybersecurity posture.",
    },
    {
      question: "Is phishing simulation suitable for organizations of all sizes?",
      answer: "Yes, phishing simulation is beneficial for organizations of all sizes. Cyber threats, including phishing attacks, target organizations indiscriminately. By implementing phishing simulation small, medium, and large organizations can educate and empower their employees to defend against phishing attacks and mitigate the risk of data breaches. ",
    },
    {
      question: "How often should phishing simulations be conducted?",
      answer: "The frequency of phishing simulations may vary based on organizational needs and risk factors.Generally, it is recommended to conduct phishing simulations at regular intervals, such as monthly or quarterly, to keep employees engaged, reinforce awareness, and address emerging threat , effectively. However, the specific frequency can be determined based on factors such as industry ,  regulations, past incidents, and employee feedback. ",
    },
    {
      question: "Can phishing simulation actually reduce the risk of successful phishing attacks?",
      answer: "Yes, phishing simulation can significantly reduce the risk of successful phishing attacks. By providing employees with hands-on training and exposing them to realistic phishing scenarios, they develop a heightened sense of awareness and become more adept at identifying and reporting phishing attempts. This proactive approach helps create a culture of cybersecurity within the organization  and reduces the likelihood of falling victim to phishing attacks.",
    },
    {
      question: "How does phishing Simulation ensure the privacy and security of user data?",
      answer: "Phishinstinct prioritizes the privacy and security of user data by following strict data protection protocols and adhering to industry best practices. User data, including email addresses an simulation results, is handled with utmost confidentiality and stored securely. We are committed maintaining a secure platform and protecting customer information from unauthorized access or misuse. ",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      name="faqs"
      className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-gray-700 mb-20"
      style={{ width: "80%" }}
    >
      <h1 className="text-4xl font-bold mb-10 mt-20 text-gray-900 uppercase text-center">
        FAQs
      </h1>
      {faqsData.map((faq, index) => (
        <div
          key={index}
          className="border p-4 mb-4 rounded-lg transition ease-in-out duration-300 transform hover:shadow-md"
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <h2 className="text-lg font-medium">{faq.question}</h2>
            <button className="text-gray-500 focus:outline-none">
              {activeIndex === index ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div
            className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${
              activeIndex === index ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
            }`}
            style={{ transitionProperty: "max-height, opacity" }}
          >
            <p className="mt-2">{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
